import React from "react";
import Select from "react-select";

import { formatDate } from "../../utils/date";
import closeIcon from "../../images/close.png";

export default function CompareList({
  markets,
  marketOptions,
  marketSelectChange,
  productSelectChange,
  deadlineSelectChange,
  removeMarket,
  addMarket,
}) {
  return (
    <div className="compare">
      <ul>
        {markets.map((market, index) => (
          <li key={index}>
            {markets.length > 1 ? (
              <button
                className="close"
                type="button"
                onClick={() => removeMarket(index)}
              >
                <img src={closeIcon} alt="bezár ikon" />
              </button>
            ) : (
              ""
            )}

            <div className="inline">
              <Select
                options={marketOptions}
                placeholder={`Piac`}
                value={{
                  value: market.id,
                  label: `${market.name} (${market.city})`,
                }}
                style={{ width: "135px" }}
                onChange={({ value }) => marketSelectChange(index, value)}
              />
            </div>
            <div className="inline">
              <Select
                className="select product"
                style={{ width: "50%" }}
                options={market.productOptions}
                placeholder={`Termény`}
                value={
                  market.selectedProduct
                    ? {
                        value: market.selectedProduct.id,
                        label: market.selectedProduct.name,
                      }
                    : null
                }
                onChange={({ value }) => productSelectChange(index, value)}
                isDisabled={market.products && market.products.length === 0}
              />
            </div>
            <div className="inline">
              <Select
                options={market.deadlineOptions}
                placeholder={`Határidő`}
                value={
                  market.selectedDeadline
                    ? {
                        value: market.selectedDeadline.date,
                        label: formatDate(
                          market.selectedDeadline.date,
                          "yyyy. MMMM"
                        ),
                      }
                    : null
                }
                onChange={({ value }) => deadlineSelectChange(index, value)}
                isDisabled={market.deadlines && market.deadlines.length === 0}
              />
            </div>
          </li>
        ))}
      </ul>
      {markets.length < 4 ? (
        <button type="button" onClick={addMarket}>
          + Új összeállítás
        </button>
      ) : (
        ""
      )}
    </div>
  );
}
