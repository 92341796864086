import React from "react";

import {formatDate} from "../utils/date";
import leftIcon from "../images/left.png";
import rightIcon from "../images/right.png";

export default function Navigation({previousMarkets, nextMarkets, navigateToPreviousPage, navigateToNextPage}) {
  return (
    <div className="inline navigation">
      {previousMarkets.length ? (
        <div>
          <label>Előző határidő</label>
          <button
            type="button"
            className="inline"
            onClick={() => navigateToPreviousPage()}
          >
            <img src={leftIcon} alt="bal nyíl" />
            <div>
              {previousMarkets.map((market, index) => (
                <p key={index}>
                  <span className="product-name">
                    {market.selectedProduct.name}
                  </span>{" "}
                  {`${formatDate(
                    market.selectedDeadline.date,
                    "yyyy. MMMM"
                  )} (${market.name}, ${market.city})`}
                </p>
              ))}
            </div>
          </button>
        </div>
      ) : (
        ""
      )}
      {nextMarkets.length ? (
        <div>
          <label>Következő határidő</label>
          <button
            type="button"
            className="inline"
            onClick={() => navigateToNextPage()}
          >
            <div>
              {nextMarkets.map((market, index) => (
                <p key={index}>
                  <span className="product-name">
                    {market.selectedProduct.name}
                  </span>{" "}
                  {`${formatDate(
                    market.selectedDeadline.date,
                    "yyyy. MMMM"
                  )} (${market.name}, ${market.city})`}
                </p>
              ))}
            </div>
            <img src={rightIcon} alt="jobb nyíl" />
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}