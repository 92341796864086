import React from "react";
import Select from "react-select";

const customStyles = {
  control: (styles) => ({
    ...styles,
    width: 175,
    height: 40,
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    fontWeight: isSelected ? "bold" : "normal",
  }),
};

export default function CurrencySelect({
  currencyOptions,
  selectedCurrency,
  changeCurrency,
}) {
  return (
    <div className="currency">
      <Select
        options={currencyOptions}
        value={{
          value: selectedCurrency,
          label: `Pénznem: ${selectedCurrency}`,
        }}
        styles={customStyles}
        onChange={changeCurrency}
      />
    </div>
  );
}
