const queryParams = new URLSearchParams(window.location.search);

export function getInitialState() {
  const storageParams = new URLSearchParams(window.localStorage.getItem("params"));
  const params = window.location.search ? queryParams : storageParams
  const initialState = {}

  if (params.has("currency")) {
    const currency = params.get("currency");
    /*const possibleCurrencies = this.state.currencyOptions.map(
      (currency) => currency.label
    );

    if (possibleCurrencies.includes(currency)) {
      initialState.selectedCurrency = currency;
    }*/
    initialState.selectedCurrency = currency;
  }

  if (params.has("from") && params.has("until")) {
    const from = params.get("from");
    const until = params.get("until");

    if (Date.parse(from) && Date.parse(until)) {
      initialState.startDate = new Date(from);
      initialState.endDate = new Date(until);
    }
  }

  if (params.getAll("market")) {
    const markets = params.getAll("market");
    const products = params.getAll("product");
    const deadlines = params.getAll("deadline");

    const initialMarkets = markets.map(function (market, index) {
      return {
        market_id: parseInt(market),
        product_id: parseInt(products[index]),
        deadline_id: parseInt(deadlines[index])
      }
    });

    initialState.initialMarkets = initialMarkets;
  }

  return initialState;
}

export function updateMarketParams(markets) {
  if (!markets || !markets.length) {
    return;
  }

  markets
    .filter(market => market && market.selectedProduct && market.selectedDeadline)
    .forEach(function (market, index) {
      if (index === 0) {
        queryParams.set("market", market.id || "");
        queryParams.set("product", market.selectedProduct.id || "");
        queryParams.set("deadline", market.selectedDeadline.id || "");
      } else {
        queryParams.append("market", market.id || "");
        queryParams.append("product", market.selectedProduct.id || "");
        queryParams.append("deadline", market.selectedDeadline.id || "");
      }
    });

  updateBrowserState();
}

export function updateBrowserState() {
  const queryString = `?${queryParams}`;

  window.history.replaceState({}, "", queryString);
  window.localStorage.setItem("params", queryString);
}

export function setBrowserStateParam(key, value) {
  queryParams.set(key, value);
  updateBrowserState();
}