import React from "react";

import {formatDate} from "../../utils/date";

function getDeadlineShort(deadline) { 
  if (!deadline?.date) return '';
  
  const monthNames = [
    "január", "február", "március", "április", "május", "június",
    "július", "augusztus", "szeptember", "október", "november", "december",
  ];

  const year = deadline.date.getYear() + 1900;
  const monthShort = monthNames[deadline.date.getMonth()];
  
  return `${year}. ${monthShort}`;
}

const nameVariations = {
  EURONEXT: ["EURONEXT", "EURONEXT-2", "EURONEXT-2-2", "EURONEXT-2-2-2"],
  CBOT: ["CBOT", "CBOT-2", "CBOT-2-2", "CBOT-2-2-2"],
  BÉT: ["BÉT", "BÉT-2", "BÉT-2-2", "BÉT-2-2-2"],
};

export default function Indicators({ markets, colors }) {
  return (
    <div className="indicators">
      {markets.length
        ? markets.map((market, index) => (
            <p key={index}>
              <span
                className="circle"
                style={{
                  backgroundColor: colors[nameVariations[market.name][index]],
                }}
              ></span>
              <span className="product-name">
                <span>{market.selectedProduct.name}</span>{", "}
                {getDeadlineShort(market.selectedDeadline)}
              </span>{" "}
              {`${formatDate(market.selectedDeadline.current, "yyyy. MMMM")} (${
                market.name
              }, ${market.city})`}
            </p>
          ))
        : ""}
    </div>
  );
}
