import hu from "date-fns/locale/hu";
import {format} from "date-fns";

export function formatDate(date, dateFormat) {
  if (!date) {
    return "";
  }

  return format(date, dateFormat, { locale: hu });
}

export const dateLocale = hu;