import React from "react";

export default function ProductButtons({
  setMarketsByProduct,
  selectedProducts,
}) {
  return (
    <ul className="products">
      <li>
        <button
          type="button"
          className={selectedProducts.includes("kukorica") ? "active" : ""}
          onClick={() => setMarketsByProduct(2)}
        >
          Kukorica
        </button>
      </li>
      <li>
        <button
          type="button"
          className={selectedProducts.includes("malmi búza") ? "active" : ""}
          onClick={() => setMarketsByProduct(1)}
        >
          Malmi búza
        </button>
      </li>
      <li>
        <button
          type="button"
          className={selectedProducts.includes("repce") ? "active" : ""}
          onClick={() => setMarketsByProduct(3)}
        >
          Repce
        </button>
      </li>
    </ul>
  );
}
