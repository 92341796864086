import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { format } from "date-fns";

export default function Chart({
  data,
  currency,
  names,
  colors,
  width,
  height,
}) {
  const modifier = currency === "HUF" ? 1000 : 5;
  const currencySigns = {
    HUF: "HUF",
    USD: "$",
    EUR: "€",
  };

  return (
    <div className="chart">
      <h2 className="chart-title">Összehasonlítás</h2>
      <LineChart
        width={width}
        height={height}
        data={data}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
      >
        {names.map((name, index) => (
          <Line
            type="linear"
            dataKey={name}
            strokeWidth={1.5}
            stroke={colors[name]}
            key={index}
            dot={false}
            activeDot={{ fill: "red" }}
            connectNulls={true}
          />
        ))}
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis
          dataKey="name"
          tickFormatter={(value) => format(new Date(value), "yyyy-MM")}
        />
        <YAxis
          domain={[
            (dataMin) => Math.round(dataMin - modifier),
            (dataMax) => Math.round(dataMax + modifier),
          ]}
          label={{ value: `${currencySigns[currency]} / tonna`, angle: -90 }}
          width={140}
          tickCount={6}
          formatter={(value, name, props) => [
            `${name}: ${value} ${currencySigns[currency]} / tonna`,
          ]}
        />
        <Tooltip
          formatter={(value, name, props) =>
            `${value} ${currencySigns[currency]} / tonna`
          }
          labelFormatter={(value, name, props) =>
            format(new Date(value), "yyyy.MM.dd")
          }
        />
      </LineChart>
    </div>
  );
}
