import React, { Component } from "react";
import { addMonths, getMonth, getYear, getDay, isEqual } from "date-fns";

import Chart from "./components/Chart";
import ProductButtons from "./components/MarketOptions/ProductButtons";
import CompareList from "./components/MarketOptions/CompareList";
import Datepicker from "./components/ChartOptions/Datepicker";
import CurrencySelect from "./components/ChartOptions/CurrencySelect";
import Indicators from "./components/ChartOptions/Indicators";
import Navigation from "./components/Navigation";

import { formatDate } from "./utils/date";
import {
  getInitialState,
  updateMarketParams,
  setBrowserStateParam,
} from "./utils/browserState";

import "./reset.css";
import "./App.scss";

const apiUrl = process.env.REACT_APP_API_URL;

const initialData = {
  currentDeadline: new Date(),
  currentDeadline: new Date(),
  startDate: addMonths(new Date(), -4),
  selectedCurrency: "EUR",
};

export default class App extends Component {
  state = {
    markets: [],
    product: [],
    deadlines: [],
    rates: [],
    marketsToCompare: [],
    comparedMarkets: [],
    nextMarkets: [],
    previousMarkets: [],
    initialMarkets: [],
    marketOptions: [],
    currentDeadline: new Date(),
    startDate: addMonths(new Date(), -4),
    endDate: new Date(),
    selectedCurrency: "EUR",
    currencyOptions: [
      { value: "EUR", label: "EUR" },
      { value: "USD", label: "USD" },
      { value: "HUF", label: "HUF" },
    ],
    chartData: [],
    chartColors: {
      CBOT: "#506d49",
      "CBOT-2": "#156317",
      "CBOT-2-2": "#134513",
      "CBOT-2-2-2": "#15302b",
      EURONEXT: "#455461",
      "EURONEXT-2": "#1b5051",
      "EURONEXT-2-2": "#0c1f60",
      "EURONEXT-2-2-2": "#49305e",
      BÉT: "#695a4b",
      "BÉT-2": "#64391e",
      "BÉT-2-2": "#601329",
      "BÉT-2-2-2": "#544d01",
    },
    selectedProducts: [],
    uniqueMarketNames: [],
    isDatePickerActive: false,
    chartWidth: 0,
    chartHeight: 0,
  };

  setInitialFromBrowserState = () => {
    const initialState = getInitialState();

    new Promise((resolve, reject) => {
      this.setState(initialState, resolve);
    });
  };

  addMarket = () => {
    const { marketsToCompare, markets } = this.state;

    if (marketsToCompare.length < markets.length + 1) {
      const newMarket = {
        products: [],
        deadlines: [],
        selectedMarket: {},
      };

      this.setState({ marketsToCompare: [...marketsToCompare, newMarket] });
    }

    const lastMarket = marketsToCompare[marketsToCompare.length - 1];

    this.findSimilar(lastMarket.selectedProduct, lastMarket.selectedDeadline);
  };

  removeMarket = (marketIndex) => {
    const remainingMarkets = this.state.marketsToCompare.filter(function (
      _market,
      index
    ) {
      return index !== marketIndex;
    });

    this.setState({ marketsToCompare: remainingMarkets }, () =>
      this.updateMarkets()
    );
  };

  findNextMarketId = (usedMarketIds, marketIds) => {
    return marketIds
      .filter((id) => !usedMarketIds.includes(id))
      .sort((a, b) => a - b)[0];
  };

  findDeadline = (deadlines, candidate, modifier = 1) => {
    const firstDeadline = deadlines[0].date;
    const lastDeadline = deadlines[deadlines.length - 1].date;
    const endCondition =
      modifier > 0 ? lastDeadline > candidate : firstDeadline < candidate;
    const found = deadlines.find((deadline) =>
      isEqual(deadline.date, candidate)
    );

    if (!found && endCondition) {
      return this.findDeadline(
        deadlines,
        addMonths(candidate, modifier),
        modifier
      );
    }

    return found;
  };

  findSimilar = (selectedProduct, selectedDeadline) => {
    const { markets, marketsToCompare } = this.state;
    const emptyMarket = {
      products: [],
      deadlines: [],
    };
    const possibleMarketIds = markets.map((market) => market.id);
    const marketIds = marketsToCompare.map((market) => market.id);
    const uniqueMarketIds = [...new Set(marketIds)];

    if (!uniqueMarketIds.length || uniqueMarketIds.length === 3) {
      return emptyMarket;
    }

    const nextMarketId = this.findNextMarketId(
      uniqueMarketIds,
      possibleMarketIds
    );

    if (!nextMarketId) {
      return emptyMarket;
    }

    const newMarket = markets.find((market) => market.id === nextMarketId);
    if (selectedProduct) {
      newMarket.selectedProduct = newMarket.products.find(
        (product) => product.id === selectedProduct.id
      );
      newMarket.productOptions = this.setProductOptions(newMarket.products);

      if (selectedDeadline && newMarket.selectedProduct) {
        const nextDeadline = this.findDeadline(
          newMarket.selectedProduct.deadlines,
          selectedDeadline.date
        );

        newMarket.selectedDeadline = nextDeadline;
        newMarket.deadlineOptions = this.setDeadlineOptions(
          newMarket.selectedProduct.deadlines
        );
      }
    }

    this.setState({ marketsToCompare: [...marketsToCompare, newMarket] });
  };

  setMarketsByProduct = (productId) => {
    const { currentDeadline } = this.state;
    const markets = this.getMarkets(currentDeadline, productId).map(function (
      market
    ) {
      let selectedProductIdx;
      const selectedProduct = market.products.find((product, idx) => {
        let found;
        if ((found = product.id === productId)) {
          selectedProductIdx = idx;
        }
        return found;
      });

      market.selectedProduct = selectedProduct || {};

      const selectedDeadline = market.products[
        selectedProductIdx
      ].deadlines.find((deadline) => deadline.date > new Date());
      market.selectedDeadline = selectedDeadline || {};

      return market;
    });

    const previousMarkets = this.getPagination(markets, -1);
    const nextMarkets = this.getPagination(markets);

    updateMarketParams(markets);

    this.setState(
      {
        ...initialData,
        previousMarkets,
        nextMarkets,
        marketsToCompare: markets,
        comparedMarkets: markets,
      },
      () => this.getRates()
    );
  };

  setProductOptions = (products) => {
    return products.map((product) => ({
      label: product.name,
      value: product.id,
    }));
  };

  setDeadlineOptions = (deadlines) => {
    console.log({ deadlines });
    return deadlines
      .sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      })
      .slice(0, 5)
      .map(function (deadline) {
        return {
          label: formatDate(deadline.date, "yyyy. MMMM"),
          value: deadline.id,
        };
      });
  };

  getMarket = (market, date, productId, isPrevious = false) => {
    const modifier = isPrevious ? -1 : 1;
    const newMarket = { ...market };

    newMarket.selectedProduct = market.products.find(
      (product) => product.id === productId
    );
    newMarket.productOptions = this.setProductOptions(newMarket.products);

    if (newMarket.selectedProduct) {
      newMarket.selectedDeadline = this.findDeadline(
        newMarket.selectedProduct.deadlines,
        date,
        modifier
      );

      newMarket.deadlineOptions = this.setDeadlineOptions(
        newMarket.selectedProduct.deadlines
      );
    }

    return newMarket;
  };

  getMarkets = (date, productId, isPrevious = false) => {
    const { markets } = this.state;
    const newMarkets = markets
      .map((market) => this.getMarket(market, date, productId, isPrevious))
      .filter((market) => market.selectedProduct);

    return [...newMarkets];
  };

  navigateToNextPage = () => {
    const { comparedMarkets, nextMarkets } = this.state;

    const newPreviousMarkets = [...comparedMarkets];
    const newCurrentMarkets = [...nextMarkets];
    const newNextMarkets = newCurrentMarkets
      .map((market) =>
        this.getMarket(
          market,
          addMonths(market.selectedDeadline.date, 1),
          market.selectedProduct.id
        )
      )
      .filter((market) => market.selectedProduct && market.selectedDeadline);

    updateMarketParams(newCurrentMarkets);

    this.setState(
      {
        previousMarkets: newPreviousMarkets,
        marketsToCompare: newCurrentMarkets,
        comparedMarkets: newCurrentMarkets,
        nextMarkets: newNextMarkets,
      },
      () => this.getRates()
    );
  };

  navigateToPreviousPage = () => {
    const { previousMarkets, comparedMarkets } = this.state;

    const newCurrentMarkets = [...previousMarkets];
    const newNextMarkets = [...comparedMarkets];
    const newPreviousMarkets = newCurrentMarkets
      .map((market) =>
        this.getMarket(
          market,
          addMonths(market.selectedDeadline.date, -1),
          market.selectedProduct.id,
          true
        )
      )
      .filter((market) => market.selectedProduct && market.selectedDeadline);

    updateMarketParams(newCurrentMarkets);

    this.setState(
      {
        previousMarkets: newPreviousMarkets,
        marketsToCompare: newCurrentMarkets,
        comparedMarkets: newCurrentMarkets,
        nextMarkets: newNextMarkets,
      },
      () => this.getRates()
    );
  };

  setMarketsToCompare = (currentMonth, productId) => {
    const selectedMarkets = this.getMarkets(currentMonth, productId);

    this.setState({ marketsToCompare: selectedMarkets });
  };

  updateMarkets = () => {
    const markets = [...this.state.marketsToCompare];
    const selectedProducts = [];
    const filteredMarkets = markets.filter((market) => {
      if (market.selectedProduct) {
        selectedProducts.push(market.selectedProduct.name);
      }

      return market.selectedProduct && market.selectedDeadline.id;
    });
    const previousMarkets = this.getPagination(filteredMarkets, -1);
    const nextMarkets = this.getPagination(filteredMarkets);

    updateMarketParams(markets);

    this.setState(
      {
        previousMarkets,
        nextMarkets,
        selectedProducts,
        comparedMarkets: filteredMarkets,
      },
      () => this.getRates()
    );
  };

  marketSelectChange = (index, selected) => {
    const modifiedComparedMarkets = [...this.state.marketsToCompare];
    modifiedComparedMarkets[index] = {
      ...this.state.markets.find((market) => market.id === selected),
    };

    const productOptions = this.setProductOptions(
      modifiedComparedMarkets[index].products
    );
    modifiedComparedMarkets[index].productOptions = productOptions;

    this.setState({ marketsToCompare: modifiedComparedMarkets });
  };

  productSelectChange = (index, selected) => {
    const modifiedComparedMarkets = [...this.state.marketsToCompare];
    const currentMarket = modifiedComparedMarkets[index];

    currentMarket.selectedProduct = currentMarket.products.find(
      (product) => product.id === selected
    );

    currentMarket.selectedDeadline = {};
    currentMarket.deadlineOptions = this.setDeadlineOptions(
      currentMarket.selectedProduct.deadlines
    );

    this.setState({ marketsToCompare: modifiedComparedMarkets });
  };

  deadlineSelectChange = (index, selected) => {
    const modifiedComparedMarkets = [...this.state.marketsToCompare];
    const currentMarket = modifiedComparedMarkets[index];

    currentMarket.selectedDeadline = currentMarket.selectedProduct.deadlines.find(
      (deadline) => deadline.id === selected
    );
    this.setState({ marketsToCompare: modifiedComparedMarkets });
  };

  getInit = () => {
    return fetch(`${apiUrl}/rates/init`)
      .then((response) => response.json())
      .then(({ markets }) => {
        markets.forEach(function (market) {
          market.products.forEach(function (product) {
            product.deadlines.forEach(function (deadline) {
              deadline.date = new Date(deadline.date);
            });
          });
        });

        const marketOptions = markets.map((market) => ({
          label: market.name,
          value: market.id,
        }));

        this.setState({
          markets,
          marketOptions,
        });
      })
      .catch(console.log);
  };

  getRates = () => {
    const { startDate, endDate, comparedMarkets } = this.state;
    const selectedProducts = [];

    const markets = comparedMarkets
      .sort((a, b) => {
        return (
          new Date(a.selectedDeadline.date) - new Date(b.selectedDeadline.date)
        );
      })
      .map(function (market) {
        selectedProducts.push(market.selectedProduct.name);

        return {
          id: market.id,
          product: market.selectedProduct.id,
          deadline_id: market.selectedDeadline.id,
        };
      })
      .filter((market) => market.id && market.product && market.deadline_id);

    this.setState({ selectedProducts });

    const endDateAfter = new Date(endDate);
    endDateAfter.setDate(endDateAfter.getDate() + 1);

    const formattedFrom = formatDate(startDate, "yyyy-MM-dd");
    const formattedUntil = formatDate(endDateAfter, "yyyy-MM-dd");

    return fetch(`${apiUrl}/rates`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        markets,
        from: formattedFrom,
        until: formattedUntil,
      }),
    })
      .then((response) => response.json())
      .then(({ rates }) => {
        this.setState({ rates }, () => this.setChart());
      })
      .catch(console.log);
  };

  toggleDatepicker = (event) => {
    if (!event) {
      return this.setState({ isDatePickerActive: false });
    }

    if (
      !this.state.isDatePickerActive &&
      (event.target.classList.contains("datepicker-toggle") ||
        event.target.closest(".datepicker-toggle"))
    ) {
      this.setState({ isDatePickerActive: true });
    } else {
      if (!event.target.closest(".react-datepicker")) {
        this.setState({ isDatePickerActive: false });
      }
    }
  };

  startDatePickerChange = (startDate) => {
    this.setState({ startDate }, () => {
      const { endDate } = this.state;
      if (startDate) {
        setBrowserStateParam("from", formatDate(startDate, "yyyy-MM-dd"));

        if (endDate) {
          this.getRates();
        }
      }
    });
  };

  endDatePickerChange = (endDate) => {
    this.setState({ endDate }, () => {
      const { startDate } = this.state;
      if (endDate) {
        setBrowserStateParam("until", formatDate(endDate, "yyyy-MM-dd"));

        if (startDate) {
          this.toggleDatepicker();
          this.getRates();
        }
      }
    });
  };

  changeCurrency = ({ value, _label }) => {
    setBrowserStateParam("currency", value);

    this.setState({ selectedCurrency: value }, () => this.getRates());
  };

  getUniqueName = (names, current, index = 2) => {
    const nameWithIndex = `${current}-${index}`;

    if (names.includes(current)) {
      return this.getUniqueName(names, nameWithIndex);
    } else {
      return current;
    }
  };

  setChart = () => {
    const { rates, selectedCurrency } = this.state;
    const uniqueMarketNames = [];
    const chartData = rates.reduce((accumulator, current) => {
      if (!current || !current.length) {
        return accumulator;
      }

      const marketName = this.getUniqueName(
        uniqueMarketNames,
        current[0].market.name
      );

      uniqueMarketNames.push(marketName);

      current.forEach(function (rate) {
        const dateString = formatDate(new Date(rate.current), "yyyy-MM-dd");
        const price = rate[selectedCurrency.toLowerCase()] || 0;
        const existingRow = accumulator.find((row) => row.name === dateString);

        if (existingRow) {
          existingRow[marketName] = price;
        } else {
          accumulator.push({
            name: dateString,
            [marketName]: price,
          });
        }
      });

      return accumulator;
    }, []);

    chartData.sort((a, b) => {
      return new Date(a.name) - new Date(b.name);
    });

    this.setState({ chartData, uniqueMarketNames });
  };

  getPagination = (markets, modifier = 1) => {
    if (!markets || !markets.length) {
      return [];
    }

    const isPrevious = modifier < 0;

    return markets
      .filter((market) => market.selectedProduct && market.selectedDeadline)
      .map((market) =>
        this.getMarket(
          market,
          addMonths(market.selectedDeadline.date, modifier),
          market.selectedProduct.id,
          isPrevious
        )
      )
      .filter((market) => market.selectedProduct && market.selectedDeadline);
  };

  setMarketsByQueryParams = () => {
    const markets = [...this.state.markets];
    const initialMarkets = [...this.state.initialMarkets];

    const marketsToCompare = initialMarkets
      .map(({ market_id, product_id, deadline_id }) => {
        const market = { ...markets.find((market) => market.id === market_id) };

        if (market) {
          const selectedProduct = market.products.find(
            (product) => product.id === product_id
          );
          market.selectedProduct = selectedProduct || {};
          market.productOptions = this.setProductOptions(market.products);

          if (
            selectedProduct &&
            selectedProduct.deadlines &&
            selectedProduct.deadlines.length
          ) {
            const selectedDeadline = market.selectedProduct.deadlines.find(
              (deadline) => deadline.id === deadline_id
            );
            market.selectedDeadline = selectedDeadline || {};
            market.deadlineOptions = this.setDeadlineOptions(
              selectedProduct.deadlines
            );
          } else {
            market.selectedDeadline = {};
          }
        }

        return market;
      })
      .filter((market) => market);
    this.setState({ marketsToCompare });
  };

  componentDidMount() {
    document.body.addEventListener("click", this.toggleDatepicker);
    const chartElement = document.querySelector(".chart");

    this.setState({
      chartWidth: chartElement.offsetWidth,
      chartHeight: chartElement.offsetHeight,
    });

    const currentDay = getDay(new Date());
    const currentYear = getYear(new Date());
    const currentMonth =
      currentDay >= 15
        ? addMonths(getMonth(new Date()), 1)
        : getMonth(new Date());
    const currentDeadline = new Date(currentYear, currentMonth);

    this.setState({ currentDeadline });

    this.getInit()
      .then(() => this.setInitialFromBrowserState())
      .then(() => {
        const { initialMarkets } = this.state;

        if (initialMarkets && initialMarkets.length) {
          this.setMarketsByQueryParams();
        } else {
          this.setMarketsToCompare(currentDeadline, 2);
        }
      })
      .then(() => {
        const { marketsToCompare } = this.state;
        const previousMarkets = this.getPagination(marketsToCompare, -1);
        const nextMarkets = this.getPagination(marketsToCompare);

        updateMarketParams(marketsToCompare);

        this.setState(
          { previousMarkets, nextMarkets, comparedMarkets: marketsToCompare },
          () => this.getRates()
        );
      });
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.toggleDatepicker);
  }

  render() {
    const {
      marketsToCompare,
      comparedMarkets,
      previousMarkets,
      nextMarkets,
      startDate,
      endDate,
      selectedCurrency,
      currencyOptions,
      chartData,
      uniqueMarketNames,
      chartColors,
      marketOptions,
      isDatePickerActive,
      chartWidth,
      chartHeight,
    } = this.state;

    return (
      <div className="agrimarket-rates">
        <div className="container">
          <h1>Árfolyamok összehasonlítása</h1>
          <div className="inline">
            <div className="options">
              <ProductButtons
                setMarketsByProduct={this.setMarketsByProduct}
                selectedProducts={this.state.selectedProducts}
              />
              <CompareList
                markets={marketsToCompare}
                marketOptions={marketOptions}
                marketSelectChange={this.marketSelectChange}
                productSelectChange={this.productSelectChange}
                deadlineSelectChange={this.deadlineSelectChange}
                removeMarket={this.removeMarket}
                addMarket={this.addMarket}
              />
              <button
                type="button"
                onClick={this.updateMarkets}
                className="search"
              >
                Lekérdezés
              </button>
            </div>
            <div className="viz">
              <div className="inline">
                <Datepicker
                  startDate={startDate}
                  endDate={endDate}
                  isActive={isDatePickerActive}
                  startDatePickerChange={this.startDatePickerChange}
                  endDatePickerChange={this.endDatePickerChange}
                />
                <CurrencySelect
                  currencyOptions={currencyOptions}
                  selectedCurrency={selectedCurrency}
                  changeCurrency={this.changeCurrency}
                />
              </div>
              <Indicators markets={comparedMarkets} colors={chartColors} />
              <Chart
                data={chartData}
                currency={selectedCurrency}
                names={uniqueMarketNames}
                colors={chartColors}
                width={chartWidth}
                height={chartHeight}
              />
              <Navigation
                previousMarkets={previousMarkets}
                nextMarkets={nextMarkets}
                navigateToPreviousPage={this.navigateToPreviousPage}
                navigateToNextPage={this.navigateToNextPage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
