import React from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/";
import { addYears } from "date-fns";
import { formatDate, dateLocale } from "../../utils/date";
import calendarIcon from "../../images/calendar.png";

registerLocale("hu", dateLocale);

export default function Datepicker({
  startDate,
  endDate,
  isActive,
  startDatePickerChange,
  endDatePickerChange,
}) {
  return (
    <div className="datepicker">
      <button type="button" className="datepicker-toggle">
        {" "}
        {`Időintervallum: ${formatDate(
          startDate,
          "yyyy.MM.dd."
        )} - ${formatDate(endDate, "yyyy.MM.dd.")}`}
        <img src={calendarIcon} alt="dátum ikon" />
      </button>
      {isActive ? (
        <div className="calendar">
          <DatePicker
            selected={startDate}
            onChange={startDatePickerChange}
            startDate={startDate}
            locale="hu"
            inline
            minDate={addYears(new Date(), -1)}
            maxDate={new Date()}
          />
          <DatePicker
            selected={endDate}
            onChange={endDatePickerChange}
            locale="hu"
            inline
            maxDate={new Date()}
            minDate={startDate}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
